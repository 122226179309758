<template>
  <span>{{ time }}</span>
 
</template>

<script>
export default {
  name: "TimeDown",
  data() {
    return {
      time: "",
      timerSwitch: null,
    };
  },
  props: {
    endDate: String,
    currentTime: String,
  },
  created() {},
  mounted() {
    let endDate = this.endDate
    console.log(this.endDate)
    this.timerSwitch = setInterval(() => {
      this.timeDown(endDate);
      endDate-=1000
    }, 1000);
  },
  destroyed() {
    if (this.timerSwitch) {
      clearInterval(this.timerSwitch);
      this.timerSwitch = null;
    }
  },
  methods: {
    timeDown(endDate) {
      function forMat (d) {
        return d < 10 ? "0" + d : d
      }
      if (endDate <= 0) {
        if (this.timerSwitch) {
          clearInterval(this.timerSwitch);
          this.timerSwitch = null;
          this.$emit('getList','')
        }
      } else {
        let d = Math.floor(endDate / 1000 / 60 / 60 / 24)
        let h = Math.floor((endDate  /1000/ 60 / 60) % 24);
        let m = Math.floor((endDate  /1000/ 60) % 60);
        let s = Math.floor((endDate /1000) % 60);
        // this.time = `${d>0?forMat(d)+'天':''}${forMat(h)}小时${forMat(m)}分钟${forMat(s)}秒`
        this.time = `${d>0?forMat(d)+'天':''}${forMat(h)}小时${forMat(m)}分钟  未接单订单将自动取消`
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
